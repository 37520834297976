import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2} from '@angular/core';
import {ErrorAlert} from "../../models/error-alert";
import {NDCApiService} from "../../services/ndc-api.service";
import {HelpersService} from "../../services/helpers.service";
import {PaymentModuleService} from "../../services/payment-module.service";
import {convertPhoneIntoString} from "../../adapters/v1_2/adapters";
import { SeatMapService } from '../../services/seat-map.service';

@Component({
  selector: 'app-payment-info',
  templateUrl: './payment-info.component.html',
  styleUrls: ['./payment-info.component.scss']
})
export class PaymentInfoComponent implements OnInit, OnDestroy {

  @Input() order;
  @Input() shoppingResponseID;
  @Input() latestPrice;
  @Input() services = [];
  @Input() type: string;
  @Input() paymentError: ErrorAlert;
  @Output() emitInstallmentId = new EventEmitter<string>();
  @Output() emitStepBack = new EventEmitter<boolean>();

  modalContentElement: HTMLElement | null;
  paymentInfoLoader = false;
  paymentInfoError: ErrorAlert = new ErrorAlert();
  installmentOptions = [];

  consumerTax: number;
  providerTax: number;
  consumerFee: number;
  providerFee: number;
  consumerSurcharge: number;
  providerSurcharge: number;
  consumerTotal: number;
  providerTotal: number;
  consumerCurrency: string;
  providerCurrency: string;
  currencyMatch: boolean;

  constructor(private ndcApiService: NDCApiService,
              private helpers: HelpersService,
              private paymentService: PaymentModuleService,
              private seatMapService: SeatMapService,
              private renderer: Renderer2) { }

  ngOnInit(): void {
    this.modalContentElement = document.querySelector('.modal-content');
    if (this.modalContentElement) {
      this.renderer.addClass(this.modalContentElement, 'payment-form-modal-content');
    }
    this.sendPaymentInfo();
  }

  sendPaymentInfo() {
    const isNoneMethod = this.paymentService?.form?.get('method')?.value === 'none';

    if (!isNoneMethod) {
      this.paymentInfoLoader = true;
      const body: any = {
        payment: this.paymentService.getPaymentData(),
        type: this.type
      };
      if (this.shoppingResponseID) {
        body.shoppingResponseID = this.shoppingResponseID;
      } else {
        body.id = this.order.id;
      }
      if (body.payment && body.payment.phone) {
        body.payment.phone = convertPhoneIntoString(body.payment.phone);
      }

      const services = [
        ...this.helpers.mapServices(this.services),
        ...this.seatMapService.mapSeats(this.seatMapService.selectedSeats)
      ];

      if (services.length) {
        body.services = services;
      }

      this.ndcApiService.sendPaymentInfo(body)
        .then(res => {
          this.paymentInfoLoader = false;
          if (res) {
            this.installmentOptions = res.installmentOptions;
            this.paymentService.selectedInstallmentId = this.installmentOptions?.[0]?.installmentId;

            const price = res.price;
            if (price) {
              this.consumerTax = price.consumer?.tax?.total;
              this.providerTax = price.provider?.tax?.total;
              this.consumerFee = price.consumer?.fee?.total;
              this.providerFee = price.provider?.fee?.total;
              this.consumerSurcharge = price.consumer?.surcharge;
              this.providerSurcharge = price.provider?.surcharge;
              this.consumerTotal = price.consumer?.total;
              this.providerTotal = price.provider?.total;
              this.consumerCurrency = price.consumer?.currency;
              this.providerCurrency = price.provider?.currency;
              this.currencyMatch = this.consumerCurrency === this.providerCurrency;
            }
          }
        }).catch(err => {
          this.paymentInfoLoader = false;
          this.paymentInfoError = this.helpers.getError(err);
      });
    } else {
      this.emitStepBack.emit(true);
    }
  }

  onInstallmentOptionChange(installmentId: string, isChecked: boolean) {
    this.paymentService.selectedInstallmentId = isChecked ? installmentId : '';
  }

  ngOnDestroy() {
    if (this.modalContentElement) {
      this.renderer.removeClass(this.modalContentElement, 'payment-form-modal-content');
    }
  }

}
