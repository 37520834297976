import {
  ApplicationRef,
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild, HostListener, Input, Output, EventEmitter, Provider,
} from '@angular/core';
import { MinMaxDate } from '../../shared/interfaces/date';
import { DateService } from '../../shared/services/date.service';
import {Subject} from 'rxjs';
import {EditFrequentFlyerNumber, FrequentFlyerNumber, Profile, ProfileForm} from '../../shared/types/profile';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import { NgbDateParserFormatter, NgbModal, NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute} from '@angular/router';
import {HubProfileService} from '../../shared/services/hub-profile.service';
import {HelpersService} from '../../shared/services/helpers.service';
import {Dictionary} from '../../shared/types/dictionary';
import {takeUntil} from 'rxjs/operators';
import {ErrorResponse, MetaResponse, RESPONSE_ERROR, RESPONSE_OK} from '../../shared/types/system';
import {NotificationService} from '../../shared/services/notification.service';
import {HubCorporateService} from '../../shared/services/hub-corporate.service';
import {Corporate} from '../../shared/types/corporate';
import {Observable} from "rxjs/internal/Observable";
import {Location} from '@angular/common';
import {AVAILABLE_TITLES, GENDERS} from "../../shared/constants";
import {forbiddenValueValidator} from "../../shared/validators/forbidden-value.validator";
import {FormsService} from "../../shared/services/forms.service";
import {DateValidators} from "../../shared/validators/date.validators";
import { AirGatewayDateFormatter } from '../../shared/services/air-gateway-date-formatter.service';


@Component({
  selector: 'app-profile-detail',
  templateUrl: './profile-detail.component.html',
  styleUrls: ['./profile-detail.component.scss'],
  providers: [
    <Provider>{
      provide: NgbDateParserFormatter,
      useClass: AirGatewayDateFormatter,
    }
  ]
})
export class ProfileDetailComponent implements OnInit, AfterViewChecked, OnDestroy {
  @Input() isModal = false;
  @Input() corporateID: string;
  @Output() emitClose = new EventEmitter();

  @ViewChild(NgbTabset, {static: false}) tabset;
  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  serverErrors = {};
  isNewRecord: boolean;
  profile: Profile = new Profile();
  selectedProfile: Profile = null;
  form: FormGroup;
  formControlNames = {
    name: 'name',
    role: 'role',
    has_travel_profile: 'has_travel_profile',
    surname: 'surname',
    title: 'title',
    gender: 'gender',
    email: 'email',
    birthdate: 'birthdate',
    document_type: 'type',
    document_id: 'id',
    document_expiration_date: 'expiration_date',
    document_fiscal_name: 'fiscal_name',
    document_issuing_country_code: 'issuing_country_code',
    document_citizenship_country_code: 'citizenship_country_code',
    document_residence_country_code: 'residence_country_code',
    phone_country_code: 'phone_country_code',
    phone_code: 'phone_code',
    phone_number: 'phone_number',
    address_country_code: 'address_country_code',
    address_postal_code: 'address_postal_code',
    address_city_name: 'address_city_name',
    address_street: 'address_street',
    frequent_flyer_numbers: 'frequent_flyer_numbers',
  };
  validateTriggered = false;
  airlines: string[] = [];

  countries = Dictionary.Countries;

  fields = [];
  viewFields: any = {};
  profileId: string;
  corporate: Corporate = new Corporate();
  showAddedEditedLoaderInFreqentlyNumberForm = false;
  showRemovedLoaderInFreqentlyNumberForm = false;

  minMaxDateOfBirth: MinMaxDate;

  telInputObject;
  isEdit = true;
  lastRole = '';

  breadcrumbs = [];
  unsavedChanges = false;
  editableControlName: string;
  digitsPattern = /^\d+$/;
  titles = AVAILABLE_TITLES;
  genders = GENDERS;
  documentTypes = Dictionary.DocumentTypes;
  previousTab: string;
  errors: any;


  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !this.unsavedChanges;
  }

  constructor(private route: ActivatedRoute,
              private _notificationSvc: NotificationService,
              private corporateService: HubCorporateService,
              public service: HubProfileService,
              public helpers: HelpersService,
              private fb: FormBuilder,
              public modalService: NgbModal,
              public dateService: DateService,
              private appRef: ApplicationRef,
              private readonly changeDetectorRef: ChangeDetectorRef,
              private location: Location,
              private formsService: FormsService) {
  }

  ngOnInit() {
    this.setAirlines();
    this.buildForm();
    if (!this.corporateID) {
      this.corporateID = this.route.snapshot.paramMap.get('corporate_id');
    }
    this.profileId = this.route.snapshot.paramMap.get('id');
    this.isNewRecord = this.profileId === 'add' || this.isModal;
    this.getCorporate();

    if (!this.isNewRecord) {
      this.getProfileInfo();
    }
    this.minMaxDateOfBirth = this.dateService.getMinMaxDate(true, 18, 100);

    if (this.isEdit) {
      this.form.enable();
    }
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  getCorporate(isModalOpened = false) {
    this.corporateService.get(this.corporateID)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((response: any) => {
        const res = response.body;
        this.corporate = this.corporateService.corporate = res;
        this.setBreadcrumbsLinks();
        if (isModalOpened) {
          this.emitClose.emit();
        }
      });
  }

  getProfileInfo() {
    this.service.get(this.profileId)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((response: any) => {
        this.profile = response.body;
        if (this.profile?.documents) {
          this.profile.documents.map(document => {
            this.addDocument();
            return document.expiration_date ? document.expiration_date = HelpersService.getStructFromDate(new Date(document.expiration_date)) : document
          });
        }
        const values = {
          corporate_id: this.corporateID,
          name: this.profile.name,
          role: this.profile.role,
          has_travel_profile: this.profile.has_travel_profile,
          surname: this.profile.surname,
          gender: this.profile.gender || '',
          title: this.profile.title,
          email: this.profile.email,
          birthdate: this.profile.birthdate ? HelpersService.getStructFromDate(new Date(this.profile.birthdate), false) : null,
          documents:  this.profile.documents,
          phone_country_code: this.profile.phone_country_code,
          phone_code: this.profile.phone_code,
          phone_number: this.profile.phone_number,
          address_country_code: this.profile.address_country_code,
          address_postal_code: this.profile.address_postal_code,
          address_city_name: this.profile.address_city_name,
          address_street: this.profile.address_street,
          frequent_flyer_numbers: this.profile.frequent_flyer_numbers,
        };
        this.fields = this.profile.frequent_flyer_numbers ? [...this.profile.frequent_flyer_numbers] : [];

        if (this.telInputObject && this.profile.phone_country_code) {
          this.telInputObject.setCountry(this.profile.phone_country_code);
        }

        this.form.patchValue(values);
        if (!this.isEdit) {
          this.form.disable();
        }
        this.previousTab = this.profile.role === 'manager' ? 'managers' : 'travelers';
        this.setBreadcrumbsLinks();
      });
  }

  setBreadcrumbsLinks() {
    this.breadcrumbs = [
      {
        label: 'Corporates',
        url: '/corporates',
      },
      {
        label: this.corporateService.corporate?.name || '',
        url: `/corporates/${this.corporateID}`
      },
      {
        label: this.previousTab?.charAt(0).toUpperCase() + this.previousTab?.slice(1),
        url: `/corporates/${this.corporateID}`,
        queryParams: {tab: this.previousTab},
        hideActive: true
      },
    ];
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  private buildForm() {
    this.form = this.fb.group({
      name: [{value: '', disabled: !this.isEdit}, [Validators.required]],
      role: [{value: this.service.role || 'traveler', disabled: !this.isEdit}, [Validators.required]],
      has_travel_profile: [{value: true, disabled: !this.isEdit}],
      surname: [{value: '', disabled: !this.isEdit}, [Validators.required]],
      gender: [{value: '', disabled: !this.isEdit}],
      title: [{value: '', disabled: !this.isEdit}],
      email: [{value: '', disabled: !this.isEdit}, [Validators.email]],
      birthdate: [{value: null, disabled: !this.isEdit}],
      documents: this.fb.array([]),
      phone_country_code: [{value: '', disabled: !this.isEdit}],
      phone_code: [{value: '', disabled: !this.isEdit}],
      phone_number: [{value: '', disabled: !this.isEdit}],
      address_country_code: [{value: '', disabled: !this.isEdit}],
      address_postal_code: [{value: '', disabled: !this.isEdit}],
      address_city_name: [{value: '', disabled: !this.isEdit}],
      address_street: [{value: '', disabled: !this.isEdit}],
      frequent_flyer_numbers: [],
    });

    this.form.valueChanges.subscribe((data) => {
      let setTravelProfile = false;
      if (this.lastRole !== data.role) {
        this.setBreadcrumbsLinks();
        if (data.role === 'traveler') {
          setTravelProfile = true;
        }
      }
      this.lastRole = data.role;
      if (setTravelProfile) {
        this.form.get('has_travel_profile').setValue(true);
      }
    });
    this.updateFormValidation();
  }

  save(isCancelEdit = true) {
    this.checkIfPhoneIsSet();

    if (!this.validate()) {
      return false;
    }
    this.validateTriggered = false;
    this.serverErrors = {};

    let p;
    if (this.selectedProfile === null) {
      p = this.profile;
    } else {
      p = this.selectedProfile;
    }

    this.populateModel();

    if (this.isNewRecord) {
      this.service.create(p)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe((response: any) => {
          let res = response.body;
          if (res.status === RESPONSE_OK) {
            this.isNewRecord = false;
            this.hideLoadersInForm();
            this.profile.id = (<MetaResponse>res).meta;
            this.successSave(isCancelEdit);
            this.setBreadcrumbsLinks();
            if (this.isModal) {
              this.getCorporate(true);
            } else if (this.profileId !== 'add')  {
              this.getProfileInfo();
            }
            this.unsavedChanges = false;
          } else if (res.status === RESPONSE_ERROR) {
            res = <ErrorResponse>res;
            this.serverErrors = res.error;
            this.setValidatorsForFormControlsBasedOnServerErrors();
            this.hideLoadersInForm();
            this.helpers.scrollTo(0, 300);
          }
        });
    } else {
      this.service.update(p)
        .pipe(takeUntil(this.ngUnsubscribe$))
        .subscribe((response: any) => {
          let res = response.body;
          if (res.status === RESPONSE_OK) {
            this.form.markAsPristine();
            this.successSave(isCancelEdit);
            this.hideLoadersInForm();
            this.unsavedChanges = false;
          } else if (res.status === RESPONSE_ERROR) {
            res = <ErrorResponse>res;
            this.serverErrors = res.error;
            this.setValidatorsForFormControlsBasedOnServerErrors();
            this.hideLoadersInForm();
            this.helpers.scrollTo(0, 300);
          }
        });
    }
  }

  populateModel() {
    const data = this.form.value;

    if (data?.documents) {
      data.documents.map(document => document.expiration_date ? document.expiration_date = HelpersService.getFormattedDate(document.expiration_date) : document);
    }

    const applyObject = {
      corporate_id: this.corporateID,
      name: data.name,
      surname: data.surname,
      role: data.role,
      has_travel_profile: data.has_travel_profile,
      title: data.title,
      gender: data.gender,
      email: data.email,
      birthdate: data.birthdate ? HelpersService.getFormattedDate(data.birthdate) : null,
      documents: data.documents,
      phone_country_code: data.phone_country_code,
      phone_code: data.phone_code,
      phone_number: data.phone_number,
      address_country_code: data.address_country_code,
      address_postal_code: data.address_postal_code,
      address_city_name: data.address_city_name,
      address_street: data.address_street,
      frequent_flyer_numbers: this.fields,
    };
    if (this.selectedProfile === null) {
      this.profile = Object.assign(this.profile, applyObject);
    } else {
      this.selectedProfile = Object.assign(this.selectedProfile, applyObject);
    }
  }

  deleteProfile() {
    this.service.delete(this.profile.id)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((response: any) => {
        let res = response.body;
        if (res.status === 'OK') {
          this.location.back();
        }
      }, (error => {
        console.log(error);
      }));
  }

  setValidatorsForFormControlsBasedOnServerErrors() {
    if (this.serverErrors['email']) {
      const emailControl = this.form.get('email');
      emailControl.setValidators([Validators.email, forbiddenValueValidator(emailControl.value)]);
      emailControl.updateValueAndValidity();
    }
  }

  successSave(isCancelEdit = true, message = 'Profile has been successfully saved') {
    message = message.charAt(0).toUpperCase() + message.slice(1);
    this._notificationSvc.clearNotifications();
    this._notificationSvc.success('SUCCESS', message, 0);
    if (this.selectedProfile !== null && isCancelEdit) {
      this.cancel();
    }
  }

  open(content, size: any = 'lg') {
    this.modalService.open(content, {
      size
    })
      .result.then((result) => {
    }, (reason) => {
    });
  }

  editFrequentFlyerNumber(field: EditFrequentFlyerNumber) {
    this.fields[field.idx] = field.frequentFlyerNumber;
    if (!this.isNewRecord) {
      this.form.enable();
      this.showAddedEditedLoaderInFreqentlyNumberForm = true;
      this.editTraveler();
      this.hideLoadersInForm();
    }
    this.detectUnsavedFrequentFlyerNumbers();
  }

  deleteFrequentFlyerNumber(field: FrequentFlyerNumber) {
    this.fields = this.fields.filter(f => f.alliance !== field.alliance);
    if (!this.isNewRecord) {
      this.form.enable();
      this.showRemovedLoaderInFreqentlyNumberForm = true;
      this.editTraveler();
    }
    this.detectUnsavedFrequentFlyerNumbers();
  }

  addNewFrequentFlyerNumber(event: FrequentFlyerNumber) {
    this.editTraveler();
    let isNew = true;
    const newFrequentFlyerNumber = event;
    this.fields.map((item) => {
      if (item.alliance === newFrequentFlyerNumber.alliance) {
        isNew = false;
      }
    });

    if (isNew && newFrequentFlyerNumber) {
      if (!this.isNewRecord) {
        this.form.enable();
        this.showAddedEditedLoaderInFreqentlyNumberForm = true;
        this.fields.push(newFrequentFlyerNumber);
      } else {
        this.fields.push(newFrequentFlyerNumber);
      }
      this.hideLoadersInForm();
    }
    this.detectUnsavedFrequentFlyerNumbers();
  }

  detectUnsavedFrequentFlyerNumbers() {
    let currentFFNValue = this.form.get('frequent_flyer_numbers').value;
    if (!this.unsavedChanges) {
      this.unsavedChanges = JSON.stringify(currentFFNValue) !== JSON.stringify(this.fields);
    }
  }

  onCountryChange($event: any) {
    this.form.get('phone_code').setValue($event.dialCode);
    this.form.get('phone_country_code').setValue($event.iso2);
  }

  onTelInputObject($event: any) {
    this.telInputObject = $event;
    let selectedCountry = this.form.get("phone_country_code").value;
    this.helpers.onTelInputObject(this.telInputObject, selectedCountry);
  }

  checkIfPhoneIsSet() {
    if (!this.form.get("phone_country_code").value || !this.form.get("phone_code").value) {
      this.onCountryChange(this.telInputObject.s);
    }
  }


  validate() {
    this.errors = {};
    this.validateTriggered = true;

    if (!this.form.valid) {
      for (let key in this.form.value) {
        let control = this.form.get(key);
        if (control.value && typeof (control.value) === 'object') {
          let errors = this.formsService.validateObject(control);
          for (let k in errors) {
            this.errors = {...this.errors, ...errors};
          }
        } else {
          if (!control.valid) {
            this.errors[key] = control.errors;
          }
        }
      }
    }

    return this.form.valid;
  }

  private setAirlines() {
    this.airlines = Object.keys(Dictionary.ProvidersMapping);
  }

  private hideLoadersInForm() {
    this.showAddedEditedLoaderInFreqentlyNumberForm = false;
    this.showRemovedLoaderInFreqentlyNumberForm = false;
  }

  editTraveler() {
    this.form.enable();
    this.isEdit = true;
    if (!this.unsavedChanges) {
      this.detectUnsavedChanges();
    }
  }

  detectUnsavedChanges() {
    let initialFormValue = this.form.value;
    this.form.valueChanges.subscribe((currentValue) => {
      if (this.isEdit) {
        this.unsavedChanges = JSON.stringify(currentValue) !== JSON.stringify(initialFormValue);
      } else {
        this.unsavedChanges = false;
      }
    });
  }


  cancel() {
    this.editableControlName = '';
    this.form.markAsPristine();
    this.emitClose.emit();
    this.selectedProfile = null;

    const values = {
      corporate_id: this.profile.corporate_id,
      role: this.profile.role,
      has_travel_profile: this.profile.has_travel_profile || this.profile.role === 'traveler',
      name: this.profile.name,
      surname: this.profile.surname,
      gender: this.profile.gender || '',
      title: this.profile.title,
      email: this.profile.email,
      birthdate: HelpersService.getStructFromDate(new Date(this.profile.birthdate), false),
      documents: this.profile.documents,
      phone_country_code: this.profile.phone_country_code,
      phone_code: this.profile.phone_code,
      phone_number: this.profile.phone_number,
      address_country_code: this.profile.address_country_code,
      address_postal_code: this.profile.address_postal_code,
      address_city_name: this.profile.address_city_name,
      address_street: this.profile.address_street,
      frequent_flyer_numbers: this.profile.frequent_flyer_numbers,
    };
    this.fields = this.profile.frequent_flyer_numbers ? [...this.profile.frequent_flyer_numbers] : [];

    const documentsArray = this.form.get('documents') as FormArray;
    documentsArray.controls.map((control, idx) => {
      if (!this.profile.documents[idx]) {
        documentsArray.removeAt(idx);
      }
    });

    this.form.patchValue(values);
    this.appRef.tick();
    this.form.patchValue(values);
    this.unsavedChanges = this.isNewRecord = this.validateTriggered = false;
  }

  editTravelerViaFormControl(controlName: string) {
    this.editableControlName = controlName;
    this.editTraveler();
  }

  updateFormValidation() {
    let form = this.form;

    form.get('has_travel_profile').valueChanges.subscribe(profileEnabled => {
      let elements = [
        'title',
        'gender',
        'birthdate',
        'phone_country_code',
        'phone_code',
        'phone_number',
      ];
      let validators = {
        'title': [Validators.required],
        'gender': [Validators.required],
        'birthdate': [Validators.required, DateValidators.ngbDateVaidator],
        'phone_country_code': [Validators.required],
        'phone_code': [Validators.required],
        'phone_number': [Validators.pattern(this.digitsPattern)],
      };
      if (profileEnabled) {
        for (let i in elements) {
          form.get(elements[i]).setValidators(validators[elements[i]]);
        }
      } else {
        for (let i in elements) {
          form.get(elements[i]).clearValidators();
        }
      }
      for (let i in elements) {
        form.get(elements[i]).updateValueAndValidity();
      }
    });
  }

  buildDocument(type?: string) {
    const documentControl = this.fb.group({
      'id': ['', [Validators.required]],
      'type': [type || '', [Validators.required]],
      'expiration_date': [null],
      'fiscal_name': [''],
      'issuing_country_code': [''],
      'citizenship_country_code': [''],
      'residence_country_code': [''],
    });

    return documentControl;
  }

  addDocument(type?: string) {
    const control = this.buildDocument(type);
    (this.form.get('documents') as FormArray).push(control);
  }

  removeDocument(index: number) {
    const arrayControl = this.form.get('documents') as FormArray;
    arrayControl.removeAt(index);
    this.unsavedChanges = true;
    this.detectUnsavedChanges();
  }

  onBirthdateChanged(dateString: string) {
    this.dateService.updateDateControl(
      dateString,
      this.form,
      'birthdate',
      this.minMaxDateOfBirth.minDate,
      this.minMaxDateOfBirth.maxDate,
    );
  }

  onExpirationDateChanged(dateString: string, control: FormGroup) {
    this.dateService.updateDateControl(
      dateString,
      control,
      'expiration_date',
    );
  }
}
