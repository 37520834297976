import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayNewSegment'
})
export class DisplayNewSegmentPipe implements PipeTransform {

  transform(segment: any, provider: string): boolean {
    // List of providers that require segmentType
    const specialProviders = ['AF', 'KL'];

    // If provider is not in the special list, or if it is in the list and segmentType exists, display the segment
    return !specialProviders.includes(provider) || (specialProviders.includes(provider) && segment.detail?.segmentType);
  }

}
