import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-services-table',
  templateUrl: './services-table.component.html',
  styleUrls: ['./services-table.component.scss']
})
export class ServicesTableComponent implements OnInit {

  @Input() services;
  @Input() passengerRef;
  @Input() selectedServicesCopy;
  @Input() selectedServicesMapCopy;
  @Input() validationErrors;
  @Output() emitUpdatedValues = new EventEmitter<{
    selectedServicesCopy: any[],
    selectedServicesMapCopy: { [key: string]: boolean },
    validationErrors: { [key: string]: string }
  }>();

  constructor() { }

  ngOnInit(): void {
  }

  toggleServiceSelection(service, passengerRef) {
    const code = this.getServiceKey(service, passengerRef);
    const copyService = JSON.parse(JSON.stringify(service));
    if (this.selectedServicesMapCopy[code]) {
      service.bookingInstructions?.instructions?.forEach((instruction, instructionIdx) => {
        delete this.validationErrors[code + instructionIdx];
      });
      delete this.selectedServicesMapCopy[code];
      this.selectedServicesCopy = this.selectedServicesCopy.filter((srv) => {
        return srv.serviceID + srv.segmentReferences + srv.travelerReferences !== copyService.serviceID + copyService.segmentReferences + passengerRef;
      });
    } else {
      copyService.travelerReferences = passengerRef;
      if (service.bookingInstructions.mandatoryText === 'mandatory') {
        service.bookingInstructions?.instructions?.forEach((instruction, instructionIdx) => {
          this.validationErrors[code + instructionIdx] = 'Required field';
        });
      }
      this.selectedServicesMapCopy[code] = true;
      this.selectedServicesCopy.push(copyService);
    }

    this.emitUpdatedValues.emit({
      selectedServicesCopy: this.selectedServicesCopy,
      selectedServicesMapCopy: this.selectedServicesMapCopy,
      validationErrors: this.validationErrors
    });
  }

  atLeastOneHaveComment(services) {
    let hasComment = false;
    services.map(s => {
      if (s.bookingInstructions && s.bookingInstructions.mandatoryText !== 'not_allowed') {
        hasComment = true;
      }
    });
    return hasComment;
  }

  showCommentInput(srv: any, passengerRef: string) {
    return srv.ref !== 'Seat' &&
      this.selectedServicesMapCopy[srv.serviceID + passengerRef + srv.segmentReferences] &&
      srv.bookingInstructions && srv.bookingInstructions.mandatoryText !== 'not_allowed';
  }

  getServiceValue(service: any, passengerRef: string, instructionIdx: number) {
    const code = this.getServiceKey(service, passengerRef);

    const selectedService = this.selectedServicesCopy.find(selectedService => {
      let selectedServiceCode = selectedService.serviceID + selectedService.travelerReferences + selectedService.segmentReferences;
      return selectedServiceCode === code;
    });

    return selectedService.bookingInstructions?.instructions?.[instructionIdx]?.value || '';
  }

  handleCommentInput($event: Event, service: any, passengerRef: string, instruction: any, instructionIdx: number) {
    this.validationErrors = {};
    const code = this.getServiceKey(service, passengerRef);
    this.selectedServicesCopy.forEach((srv) => {
      const currentCode = this.getServiceKey(srv, srv.travelerReferences);
      if (currentCode === code) {
        srv.text = srv.fieldByFieldText ?? srv.text;
        delete srv.fieldByFieldText;
        delete srv.isFreeText;

        if (srv?.bookingInstructions?.instructions?.[instructionIdx]) {
          srv.bookingInstructions.instructions[instructionIdx].value = ($event.target as HTMLInputElement).value.toUpperCase();
        }
        let newValue = ($event.target as HTMLInputElement).value.toUpperCase();
        let text = srv.text || '';

        // Create a regex to find the varkey in the text
        let regex = new RegExp(`(${instruction.varkey}\\w*)`, 'g');
        let found = text.match(regex);

        if (found) {
          // Replace the existing varkey value
          text = text.replace(regex, `${instruction.varkey}${newValue}`);
        } else {
          // Add the new varkey value
          text += ` ${instruction.varkey}${newValue}`;
        }

        // Update the text in the selected service copy
        srv.text = text.trim();

        // Update quantity based on quantity related varkeys
        if (instruction.varkey?.toUpperCase() === 'UNITS' || (instruction.varkey?.toUpperCase() === 'WVAL' && service.bookingInstructions.priceBaseOnWeight)) {
          srv.quantity = +newValue || 1;
        }
      }

      this.updateService(srv);
      this.validateInstructions(srv, currentCode);
    });

    this.emitUpdatedValues.emit({
      selectedServicesCopy: this.selectedServicesCopy,
      selectedServicesMapCopy: this.selectedServicesMapCopy,
      validationErrors: this.validationErrors
    });
  }

  updateService(service: any): any {
    if (service.bookingInstructions?.instructions?.length) {
      let placeHolder = service.bookingInstructions.placeHolder;
      let varkeys = placeHolder.match(/%(\w+)%/g);
      let values: { [key: string]: string } = {};

      // Fill dictionary with current values from instructions
      service.bookingInstructions.instructions.forEach(instruction => {
        let value = instruction.value || '';
        values[instruction.varkey] = value;
      });

      // Reconstruct the text using the order from placeHolder
      let updatedText = placeHolder;
      varkeys?.forEach(key => {
        let cleanKey = key.replace(/%/g, '');
        updatedText = updatedText.replace(key, values[cleanKey] || '');
      });

      updatedText = updatedText.replace(/\\s\?/g, '').trim();

      service.text = updatedText;
    }
    return service;
  }

  validateInstructions(service: any, currentCode: string): void {
    if (service.bookingInstructions?.instructions?.length) {
      service.bookingInstructions.instructions.forEach((instruction, instructionIdx) => {
        const errorKey = `${currentCode}${instructionIdx}`;

        if (service.isFreeText) {
          // Clear validation errors if Free Text is selected
          delete this.validationErrors[errorKey];
        } else {
          // Perform mandatory and pattern validation
          if (!instruction.value && service.bookingInstructions.mandatoryText === 'mandatory') {
            this.validationErrors[errorKey] = 'Required field';
          } else if (instruction.pattern) {
            const pattern = new RegExp(`^${instruction.pattern}$`);
            const isMatch = pattern.test(instruction.value);
            if (!isMatch) {
              this.validationErrors[errorKey] = this.getPatternErrorMessage(instruction.pattern);
            }
          }
        }
      });
    }
  }

  storeFieldByFieldText(selectedService: any) {
    if (!selectedService.hasOwnProperty('fieldByFieldText')) {
      selectedService.fieldByFieldText = selectedService.text;
    }
  }

  adjustTextareaHeight(event: Event, service: any) {
    if (!service.isFreeText) {
      service.isFreeText = true;
      const currentCode = this.getServiceKey(service, service.travelerReferences);
      this.validateInstructions(service, currentCode);
    }

    const textarea = event.target as HTMLTextAreaElement;
    textarea.style.height = 'auto'; // Reset the height
    textarea.style.height = `${textarea.scrollHeight + 5}px`; // Set to scroll height
  }

  getPatternErrorMessage(pattern: string): string {
    // Check for digit patterns
    let digitMatch = pattern.match(/\[0-9\]\{(\d+)(?:,(\d+))?\}/);
    if (digitMatch) {
      if (digitMatch[2]) {
        return `Value must be between ${digitMatch[1]} and ${digitMatch[2]} digits.`;
      } else {
        return `Value must be ${digitMatch[1]} digits.`;
      }
    }

    // Check for uppercase letter patterns
    let letterMatch = pattern.match(/\[A-Z\]\{(\d+)(?:,(\d+))?\}/);
    if (letterMatch) {
      if (letterMatch[2]) {
        return `Value must be between ${letterMatch[1]} and ${letterMatch[2]} uppercase letters.`;
      } else {
        return `Value must be ${letterMatch[1]} uppercase letters.`;
      }
    }

    // Check for any number of digits
    if (pattern === '[0-9]*') {
      return 'Value must be any number of digits.';
    }

    // Check for any number of uppercase letters
    if (pattern === '[A-Z]*') {
      return 'Value must be any number of uppercase letters.';
    }

    // Check for specific words
    let specificWordsMatch = pattern.match(/\(\?:\s*([A-Z]+(?:\|[A-Z]+)*)\s*\)/);
    if (specificWordsMatch) {
      let options = specificWordsMatch[1].split('|').join(' or ');
      return `Value must be ${options}.`;
    }

    // Default message for unsupported patterns
    return `Invalid value. Expected pattern: ${pattern}`;
  }

  getServiceKey(service: any, passengerRef: string): string {
    return `${service.serviceID}${passengerRef}${service.segmentReferences}`;
  }

  isMatchingService(serviceObj: any, passengerRef: string, selectedService: any): boolean {
    const currentKey = this.getServiceKey(serviceObj, passengerRef);
    const selectedKey = this.getServiceKey(selectedService, selectedService.travelerReferences);
    return currentKey === selectedKey;
  }

  trackByService(index: number, service: any): string {
    return `${service.serviceID}${service.travelerReferences}${service.segmentReferences}`;
  }
}
